<script setup>
const nuxtApp = useNuxtApp()

const staticURLAdimg = useRuntimeConfig().public.staticURLAdimg
const staticURLImg = useRuntimeConfig().public.staticURLImg

const props = defineProps({
    spot: {
        type: String,
        default: null,
    },
    page: {
        type: String,
        default: null,
    },
    status: {
        type: String,
        default: 'Paid',
    },
    position: {
        type: String,
        default: null,
    },
})

const { data, status, error } = await useLazyAsyncData(
    `${props.spot}-${props.page}-${props.position}`,
    () => $fetch(`/api/campaign/bookings?spot=${props.spot}&page=${props.page}&status=${props.status}`),
    {
        transform: (data) => {
            return {
                carouselData: data.bookings?.edges.map((edge) => edge.node),
                totalCount: data.bookings.totalCount,
                fetchedAt: new Date(Date.now()),
            }
        },
        getCachedData(key) {
            const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

            if (!data) {
                return
            }

            const expirationDate = new Date(data.fetchedAt)
            expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
            const isExpired = expirationDate.getTime() < Date.now()
            if (isExpired) {
                return
            }

            return data
        },
    }
)

watchEffect(() => {
    if (error.value) {
        throw createError({
            statusCode: 500,
            message: error.value,
            fatal: false,
        })
    }
})

// Check if there are any bookings available
const totalBookings = computed(() => {
    return data.value ? data.value.carouselData.length : 0
})
</script>

<template>
    <v-row id="adcarousel">
        <v-col v-if="status === 'pending' || status === 'idle'"><app-loader /></v-col>
        <v-col v-else-if="totalBookings > 0">
            <v-carousel
                cycle
                hide-controls
                :hide-delimiters="totalBookings > 1 ? false : true"
                hide-delimiter-background
                :show-arrows="false"
                interval="6000"
                height="auto"
                direction="horizontal"
                class="rounded st-box-shadow"
            >
                <v-carousel-item
                    v-for="crsl in data.carouselData"
                    :src="
                        crsl.campaign.isInternal ? staticURLImg + crsl.campaign.img : staticURLAdimg + crsl.campaign.img
                    "
                    :alt="crsl.campaign.title"
                >
                    <nuxt-link
                        :id="crsl.campaign.urlId"
                        :data-adspot="`${crsl.spot.name}-${crsl.spot.page.name}`"
                        :data-adbrand="crsl.brand.name"
                        :data-adtitle="crsl.campaign.title"
                        :to="crsl.campaign.url"
                        :target="crsl.campaign.isInternal ? undefined : `_blank`"
                        :class="`text-decoration-none text-grey-darken-4 ${crsl.campaign.gtag}`"
                        :aria-label="`Explore ${crsl.campaign.title}`"
                        rel="noopener"
                    >
                        <v-container class="fill-height align-start"></v-container>
                    </nuxt-link>
                    <img
                        v-if="crsl.campaign.trkngPxl"
                        :style="`${crsl.campaign.trkngPxlStyle}`"
                        :src="crsl.campaign.trkngPxl"
                        :width="crsl.campaign.trkngPxlWidth"
                        :height="crsl.campaign.trkngPxlHeight"
                        :alt="crsl.campaign.title"
                        border="0"
                    />
                </v-carousel-item>
            </v-carousel>
        </v-col>
        <v-col v-else>
            <v-carousel
                cycle
                hide-controls
                hide-delimiters
                hide-delimiter-background
                :show-arrows="false"
                interval="6000"
                height="auto"
                direction="horizontal"
                class="rounded st-box-shadow"
            >
                <v-carousel-item :src="`/wbst-carousel-${props.page}-blank.webp`" alt="Spot open for booking">
                    <nuxt-link
                        :data-adspot="`${props.spot}-${props.page}`"
                        data-adbrand="None"
                        data-adtitle="Spot open for booking"
                        to="support"
                        class="text-decoration-none text-grey-darken-4 bnrclk"
                        aria-label="Contact us to book this spot"
                    >
                        <v-container class="fill-height align-start"></v-container>
                    </nuxt-link>
                </v-carousel-item>
            </v-carousel>
        </v-col>
    </v-row>
</template>

<style scoped></style>
